export default {
  namespaced: true,
  state: () => {
    return {
      isCollapse: false,
    };
  },
  getters: {},
  mutations: {
    changeCollapse(state) {
      state.isCollapse = !state.isCollapse;
    },
  },
  actions: {},
};
