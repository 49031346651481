import axios from "axios";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const request = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_URL,
  // 超时
  timeout: 10000,
})

request.interceptors.request.use((config) => {
  if (config.withCredentials && (config.url === `http://localhost:8000/login` || config.url === `http://localhost:8000/sms_login`)) {
    // document.cookie = "Admin-Token=; path=/";
    // console.log(111111111)
  }
  return config;
});


export default request
