<template>
  <div class="flex items-center justify-between h-16 w-screen">
    <!-- 头部左侧 -->
    <div class="left-panel flex items-center">
      <!-- 折叠图标 -->
      <i
        class="text-2xl cursor-pointer"
        :class="{
          'el-icon-s-fold': !isCollapse,
          'el-icon-s-unfold': isCollapse,
        }"
        @click="changeIsCollapse"></i>
      <!-- 面包屑 -->
      <breadcrum class="ml-3"/>
    </div>
    <!-- 头部右侧 -->
    <div class="right-panel">
      <user-operation/>
    </div>
  </div>
</template>

<script>
import Breadcrum from "./cpns/Breadcrum.vue";
import UserOperation from "./cpns/UserOperation.vue";

export default {
  components: {Breadcrum, UserOperation},
  data() {
    return {};
  },
  methods: {
    changeIsCollapse() {
      this.$store.commit("config/changeCollapse");
    },
  },
  computed: {
    isCollapse() {
      return this.$store.state.config.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.w-screen {
  width: 100%;
}
</style>
