<template>
  <div style="height: 100%; background: #fff">
    <div
      style="
        padding-top: 8rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        class="logo"
        :style="{ 'margin-right': !isCollapse ? '10rem' : '0' }"
        style="width: 32rem; height: 34rem; text-align: center"
      >
        <el-image style="" :src="logo"></el-image>
        <!-- <el-image style="" :src="img" fit="fill"></el-image> -->
      </div>
      <div
        v-if="!isCollapse"
        class="title"
        style="font-size: 16rem; color: #000"
      >
        {{ name }}
      </div>
    </div>
    <el-menu
      background-color="#fff"
      text-color="#000"
      class="menu"
      :collapse="isCollapse"
      router
      :default-active="defaultActive"
    >
      <menu-item
        v-for="route in siderbarRouters"
        :key="route.id"
        :item="route"
      ></menu-item>
    </el-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import logo from "@/assets/images/logo-blue.png";
import MenuItem from "./cpns/MenuItem.vue";

export default {
  components: {MenuItem},
  data() {
    return {
      name: "",
      img: "",
    };
  },
  created() {
    this.name = process.env.VUE_APP_NAME;
    this.img = require(process.env.VUE_APP_IMAGE);
    this.siderbarRouters.unshift({
      component: "LAYOUT",
      meta: {
        cache: false,
        hidden: false,
        icon: "el-icon-s-home",
        menuType: "M",
        name: "首页",
      },
      path: "/home",
      redirect: null,
    });
  },
  computed: {
    isCollapse() {
      return this.$store.state.config.isCollapse;
    },
    defaultActive() {
      return this.$route.path;
    },
    logo() {
      return logo;
    },
    ...mapGetters(["siderbarRouters"]),
  },
};
</script>

<style lang="scss" scoped>
.menu:not(.el-menu--collapse) {
  width: 200rem;
}

.el-menu-item {
  background: #031624 !important;
}

.el-menu {
  border-right: none !important;
}

::v-deep .el-menu-item {
  height: 56rem;
  line-height: 56rem;
  font-size: 14rem;
  padding: 0 20rem;
}

::v-deep .el-menu-item [class^=el-icon-] {
  margin-right: 5rem;
  width: 24rem;
  font-size: 18rem;
}

::v-deep .el-submenu [class^=el-icon-] {
  margin-right: 5rem;
  width: 24rem;
  font-size: 18rem;
}

::v-deep .el-submenu .el-menu-item {
  height: 50rem;
  line-height: 50rem;
  padding: 0 45rem;
  min-width: 200rem;
}

::v-deep .el-submenu__title {
  height: 56rem;
  line-height: 56rem;
  font-size: 14rem;
}

:v-deep .el-icon-s-home:before {
  font-size: 14rem;
  width: 14rem;
}

:v-deep .el-menu-item.is-active {
  font-size: 18rem;
  padding-left: 20rem !important;
}
</style>
