import {getToken} from "@/utils/auth";
import router from "@/router";
import store from "@/store";
import NProgress from "nprogress";
import {Message} from "element-ui";

NProgress.configure({showSpinner: false});
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getToken()) {
    // console.log(store.getters["roles"].length);
    if (store.getters["roles"].length == 0) {
      store.dispatch("user/GetUserInfo").then(() => {
        store.dispatch("user/GeneratorMenu").then((res) => {
          // console.log(res);
          router.addRoutes(res);
          next({...to, replace: true});
        });
      });
    } else {
      next();
    }
  } else {
    if (to.name !== "Login") {
      next({name: "Login"});
      NProgress.done();
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
