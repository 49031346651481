import http from "@/utils/http";

export const pageApi = (params) => {
  return http({
    url: "/sys/dictData/page",
    method: "GET",
    params,
  });
};

export const updateApi = (data) => {
  return http({
    url: "/sys/dictData",
    method: "PUT",
    data,
  });
};

export const saveApi = (data) => {
  return http({
    url: "/sys/dictData",
    method: "POST",
    data,
  });
};

export const deleteApi = (id) => {
  return http({
    url: `/sys/dictData/${id}`,
    method: "DELETE",
  });
};

export const getByIdApi = (id) => {
  return http({
    url: `/sys/dictData/${id}`,
    method: "GET",
  });
};

// 根据字典类型查询字典数据信息
export const getDicts = (dictType) => {
  return http({
    url: '/sys/dictData/type/' + dictType,
    method: 'get'
  })
}