import http from "@/utils/http";

export const loginApi = (data) => {
  return http({
    url: "/login",
    method: "POST",
    data,
  });
};
export const getCaptchaApi = () => {
  return http({
    url: "/captcha",
    method: "GET",
  });
};
export const logoutApi = () => {
  return http({
    url: "/logout",
    method: "POST",
  });
};

export const getInfoApi = () => {
  return http({
    url: "/getInfo",
    method: "GET",
  });
};

export const getAllocationMenuApi = () => {
  return http({
    url: "/getMenu",
    method: "GET",
  });
};
