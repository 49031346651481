import Vuex from "vuex";
import Vue from "vue";
import getters from "./getters";

Vue.use(Vuex);

import configStore from "./modules/config";
import UserStore from "./modules/user";
import tagsView from './modules/tagsView'
import dict from "./modules/dict";

const store = new Vuex.Store({
  modules: {
    user: UserStore,
    config: configStore,
    tagsView,
    dict: dict
  },
  getters,
  // plugins: [
  //   createPersistedstate({
  //     key: "macc-pc-store",
  //     paths: ["config", "user"],
  //   }),
  // ],
});
export default store;
