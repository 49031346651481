import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import plugins from '@/plugins'
import SkuForm from 'vue-sku-form'

import "@/router/permission";
// 初始化样式
// import "normalize.css";
// 引入tailwind.css

import directive from "./directive"; // directive

import "./assets/css/tailwind.css";
import "./assets/css/index.css";
// 引入Element-UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 引入el-iconPicker
import eIconPicker from "e-icon-picker";
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import "e-icon-picker/lib/index.css"; // 基本样式，包含基本图标
import "../preflight.css";
import "@wangeditor/editor/dist/css/style.css";
import Pagination from "@/components/Pagination";
import {parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree} from "@/utils/ruoyi";
import {download} from '@/utils/request'
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
// 字典标签组件
import DictTag from '@/components/DictTag'
// 使用数据字典
import DictData from '@/components/DictData'

DictData.install()
import "nprogress/nprogress.css";

Vue.use(SkuForm)
Vue.use(directive);
Vue.use(plugins);
Vue.use(eIconPicker, {
  FontAwesome: false,
  ElementUI: true,
  eIcon: true, //自带的图标，来自阿里妈妈
  eIconSymbol: true, //是否开启彩色图标
  addIconList: [],
  removeIconList: [],
  zIndex: 3100, //选择器弹层的最低层,全局配置
});
Vue.use(ElementUI);
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('DictTag', DictTag)
Vue.prototype.resetForm = resetForm
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.parseTime = parseTime
Vue.prototype.addDateRange = addDateRange
Vue.prototype.handleTree = handleTree
// Vue.prototype.download = download

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");

